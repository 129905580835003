import React, { createContext, useEffect, useState } from 'react';

import {
  includes as _includes,
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  split as _split,
  words as _words,
  toNumber as _toNumber,
  findIndex as _findIndex,
  size as _size,
  filter as _filter,
} from 'lodash';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Box, Checkbox, IconButton } from '@material-ui/core';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { DataGrid } from '@mui/x-data-grid';

import { useUI } from '../../app/context/ui';
import { addCustomer } from '../../app/store/mm/customerSlice';
import { ListStyles } from '../../assets/css';
import AppHelper from '../../helpers/AppHelper';
import { CustomerService } from '../../services';
import { columns } from './components/list-customer-columns.table';
import ListCustomerHeader from './ListCustomerHeader';

const ListCustomer = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const locationState = location.state || [];
  const typeLog = location.state?.typeLog;
  const condition = location.state?.condition || '';
  const [isLookupWithCellphone, setIsLookupWithCellphone] = useState(false);

  const link = _isUndefined(locationState)
    ? ''
    : _isEmpty(locationState.condition)
    ?
    _isEmpty(locationState.state)
      ? ''
      : '&' + locationState.state
    : '&' + locationState.condition;

  const { blockUI, snackbarUI } = useUI();

  const [paginationURL, setPaginationURL] = useState('');

  const { pathname } = useLocation();
  const expiredCards = pathname === '/expired-card';
  const query = new URLSearchParams(useLocation().search);
  
  let checkboxItem = 0;

  const initialValuesCheckedStatus = Object.fromEntries(
    Array.from({ length: 11 }, (_, i) => [`item${i + 1}`, false])
  );

  let [checkedStatus, setCheckedStatus] = useState({
    ...initialValuesCheckedStatus,
  });

  const customerService = new CustomerService();
  const listStyle = ListStyles();

  const [rowsState, setRowsState] = useState({
    pageSize: _toNumber(query.get('pageSize')) || 50,
    rows: [],
    rowCount: 0,
    page: _toNumber(query.get('page')) || 0,
  });

  const search = `?render=paginate&limit=${rowsState.pageSize}&page=
  ${rowsState.page + 1}${link}${_includes(link, '&filter[status_orders]=0') ? '' : `${'&filter[status_orders]=1'}`}`;

  const [openButton, setOpenButton] = useState(false);
  const [selectAllBtn, setSelectAllBtn] = useState(false);
  const [item, setItem] = useState(0);
  const [valHidden, setValHidden] = useState([]);
  const [renderColumns, setRenderColumns] = useState([]);

  const getListCustomer = async (pageSize) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      const urlSearchParams = new URLSearchParams(window.location.search);
      const sort = urlSearchParams.get("sort");
      let newSort = _isEmpty(sort) ?  '&sort=-id' : `&sort=${sort}`;
      let dataProcess;
      const query = _words(search, /[^&]+/g)
            .map( (n)=> {
               return n.substring(0,5) === 'limit' &&  pageSize ? `limit=${pageSize}` : n
            })
            .slice(0, -1).join('&');
      if (query.includes("log.")) {
        newSort = "&sort=-updated_at";
      }

      if (link.includes("[has_phone]")) {
        setIsLookupWithCellphone(true);
      }

      let _query;
      if (link) {
        _query = `${query}${newSort}&include=userEdit,userNote`;
      } else {
        let _page = rowsState.page + 1;
        let _limit =  pageSize || rowsState.pageSize;
        let _filterValue =  expiredCards ? 2 : 1;
        let _filter =  expiredCards ? 'status' : 'status_orders';
        let _sort = newSort;
        let _include = 'userEdit,userNote';
        const _pageSize = rowsState.pageSize || 50;
        _query = `?render=paginate&sort=${_sort}&limit=${_limit}&filter[${_filter}]=${_filterValue}&page=${_page}&pageSize=${_pageSize}&include=${_include}`;
      }

      localStorage.setItem('query', _query);

      const r1 = link
        ? await customerService.list(`${query}${newSort}&include=userEdit,userNote`)
        : await customerService.getCustomers({
            page: rowsState.page + 1,
            limit: pageSize || rowsState.pageSize,
            filterValue: expiredCards ? 2 : 1,
            filter: expiredCards ? 'status' : 'status_orders',
            sort: newSort,
            include: 'userEdit,userNote'
          })
      dataProcess = r1.data;

      setPaginationURL(_split(dataProcess.firstPageUrl, '?')[1]);
      setRowsState((prev) => ({
        pageSize: dataProcess.perPage || prev.pageSize,
        rows: dataProcess.data,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }));

      blockUI.current.open(false)
    } catch (e) {
      blockUI.current.open(false)
      AppHelper.checkError(e, snackbarUI)
    }
  };

  const handleVisibilityUser = (id) => {
    setItem(id);
  };

  const handleChangeCheckedUserAll = (e) => {
    let attributes = e.target.parentElement.parentElement.attributes

    let status

    attributes[2].value === 'true' ? (status = true) : (status = false)

    let checkboxItem = attributes[4].value

    let idUser = parseInt(attributes[3].value)

    setCheckedStatus({ ...checkedStatus, [checkboxItem]: !status })

    if (status) {
      let updated = JSON.parse(localStorage.getItem('listClientExport')).filter(
        (e) => e !== idUser
      )
      localStorage.setItem('listClientExport', JSON.stringify(updated))
    } else {
      let updated = JSON.parse(localStorage.getItem('listClientExport'))
      updated.push(idUser)
      localStorage.setItem('listClientExport', JSON.stringify(updated))
    }
  }

  const [actionColumn, setActionColumn] = useState([
    {
      field: 'id',
      headerName: ' ',
      width: 80,
      hide: pathname === '/expired-card',
      align: 'center',
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        ++checkboxItem
        let status
        let listClientExport = JSON.parse(
          localStorage.getItem('listClientExport')
        )

        let res = listClientExport
          ? listClientExport.find((e) => e === params.value)
          : undefined

        res === undefined ? (status = false) : (status = true)

        if (res) {
          checkedStatus = { ...checkedStatus, [`item${checkboxItem}`]: true }
        }

        return (
          <>
            {pathname !== '/expired-card' ? (
              <>
                <Checkbox
                  status={`${status}`}
                  iduser={`${params.value}`}
                  checkboxitem={`item${checkboxItem}`}
                  checked={checkedStatus[`item${checkboxItem}`] || false}
                  onChange={(e) => handleChangeCheckedUserAll(e)}
                />
                <IconButton
                  aria-label='redirect'
                  size='small'
                  style={{ padding: '0px' }}
                  onClick={() => {
                    handleVisibilityUser(params.value)
                  }}>
                  <VisibilityOffIcon fontSize='inherit' />
                </IconButton>
              </>
            ) : (
              <></>
            )}
          </>
        )
      },
    },
  ])

  const handleOnPageChange = async (page) => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const newSort = !_isEmpty(sort) ? `&sort=${sort}` : '';
    const query = _words(paginationURL, /[^&]+/g)
        .map( (n)=> {
          return n.substring(0,4) === 'sort' &&  !_isEmpty(sort) ? `sort=${sort}` : n
        })
        .slice(0, -1).join('&');
    const find = query.indexOf('sort=') === -1 ? `${query}${newSort}` : query;
    try {
      blockUI.current.open(true)
      customerService.getAccessToken()
      localStorage.setItem('query', `?${find}&page=${page + 1}&pageSize=${rowsState.pageSize}`);
      const response = await customerService.pagination(`${find}`, page + 1)
      const dataProcess = response.data
      selectAllBtn
        ? localStorage.setItem('listClientExport', JSON.stringify([1]))
        : null;

      if (newSort.indexOf("&sort=-") !== -1) {
        const sortProperty = newSort.split("&sort=-")[1];
        const toCamelCase = (str) => {
          return str.replace(/[-_](.)/g, (_, char) => char.toUpperCase());
        };
        const camelCaseSortProperty = toCamelCase(sortProperty);
        dataProcess.data.sort((a, b) => {
          const trimmedA = a[camelCaseSortProperty] ? a[camelCaseSortProperty].trim() : '';
          const trimmedB = b[camelCaseSortProperty] ? b[camelCaseSortProperty].trim() : '';
          if (trimmedA === '' || trimmedA === null) {
            return 1;
          } else if (trimmedB === '' || trimmedB === null) {
            return -1;
          } else {
            return 0;
          }
        });
      }

      let rws = _filter(dataProcess.data,function(o) { return !_includes(valHidden, o.id) });

      setRowsState((prev) => ({
        ...prev,
        rows: rws,
        rowCount: dataProcess.total,
        page: dataProcess.currentPage - 1,
      }))

      blockUI.current.open(false)
    } catch (e) {
      blockUI.current.open(false)
      AppHelper.checkError(e, snackbarUI)
    }
  }

  const handleLinkContextMenu = (row, event) => {
    event.preventDefault();
    dispatch(addCustomer({ ...row }));
    localStorage.setItem('listCustomerSelect', link);
  };

  useEffect(() => {
    setRenderColumns([
      ...actionColumn,
      ...columns(handleLinkContextMenu, isLookupWithCellphone),
    ]);
  }, [isLookupWithCellphone]);

  const handleRowClick = (row) => {
    dispatch(addCustomer({ ...row }))
    const record = _findIndex(rowsState.rows, { id: row.id }) ?? 0;
    localStorage.setItem('listCustomerSelect', link)
    const urlSearchParams = new URLSearchParams(window.location.search);
    const sort = urlSearchParams.get("sort");
    const newSort = !_isEmpty(sort) ? `&sort=${sort}` : '';
    let sortProperty = newSort.split("&sort=")[1];
    let filterCustom;

    if(expiredCards){
      filterCustom = "filter[status]=2";
    }

    let activeOrderStatus = false;
    let lookup = locationState.lookup;

    if(pathname === '/customer' && lookup === undefined){
      activeOrderStatus = true;
    }

    let type = location?.state?.type || undefined;

    let url;
    if (typeLog) {
      url = `/edit-customer?tap=3&record=${record}&page=${rowsState.page}&pageSize=${rowsState.pageSize}&sortCustom=${sortProperty}&filterCustom=${filterCustom}&activeOrderStatus=${activeOrderStatus}`;
      history.push(
          url,
        { ...row, typeLog, type }
      )
    } else {
      url = `/edit-customer?record=${record}&page=${rowsState.page}&pageSize=${rowsState.pageSize}&sortCustom=${sortProperty}&filterCustom=${filterCustom}&activeOrderStatus=${activeOrderStatus}`;
      history.push(
          url,
        { ...row, condition, type }
      )
    }
  }

  const handlePageSizeChange = (pageSize) => {
    getListCustomer(pageSize)
  };

  const handleSortModelChange = (field, sort = 'asc') => {
    let order;
    const camelToSnakeCase = field.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
    switch (camelToSnakeCase) {
      case 'full_name':
        order = 'first_name,last_name';
        break;
      case 'status_name':
        order = 'status';
        break;
      case 'market_name':
        order = 'market';
        break;
      case 'user_note':
        order = 'user_note_full_name';
        break;
      default:
        order = camelToSnakeCase;
        break;
    }
    order = order + ',id';
    insertUrlParam('sort', sort === 'asc' ? order : `-${order}`);
    handleOnPageChange(rowsState.page);
  };

  const insertUrlParam = (key, value) => {
    let searchParams = new URLSearchParams(window.location.search)
    searchParams.set(key, value)
    history.push({ ...location, search: searchParams.toString() })
  };

  const providerProps = {
    checkboxItem,
    openButton,
    selectAllBtn,
    actionColumn,
    setRenderColumns,
    setActionColumn,
    handleChangeCheckedUserAll,
    handleVisibilityUser,
    setOpenButton,
    setSelectAllBtn,
    rowsState,
    search,
    query,
  };

  useEffect(() => {
    localStorage.setItem('listClientExport', JSON.stringify([]))
    getListCustomer()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    if (item > 0) {
      let arr = valHidden ?? [];
      arr.push(item);
      setValHidden(arr);
      let rws = _filter(rowsState.rows, function (o) {
        return !_includes(arr, o.id)
      });
      setRowsState((prev) => ({...prev, rows: rws}))
      setItem(0);
    }
    // eslint-disable-next-line
  }, [item]);

  return (
    <>
      <ListCustomerContext.Provider value={providerProps}>
        <ListCustomerHeader
          handleLinkContextMenu={handleLinkContextMenu}
          isLookupWithCellphone={isLookupWithCellphone}
          type={locationState.type}
        />
        <Box style={{ height: 580, width: '100%' }}>
          <DataGrid
            autoHeight
            headerHeight={56}
            rowHeight={56}
            columns={renderColumns}
            {...rowsState}
            rowsPerPageOptions={[50, 75, 100]}
            paginationMode='server'
            className={listStyle.dataGrid}
            onPageChange={(page) => {
              insertUrlParam('page', page)
              handleOnPageChange(page)
            }}
            onPageSizeChange={(pageSize) => {
              insertUrlParam('pageSize', pageSize)
              handlePageSizeChange(pageSize)
            }}
            onSortModelChange={(field) => {
              if (_size(field) > 0) {
                handleSortModelChange(field[0].field, field[0].sort);
              }
            }}
            onRowClick={(row, event) => {
              event.preventDefault();
              let nameColumn = event?.nativeEvent?.target?.innerText;
              (nameColumn !== undefined && nameColumn !== "") && handleRowClick(row.row);
            }}
            hideFooterSelectedRowCount={true}
          />
        </Box>
{/*
TODO::ContextMenu
        <ContextMenu
          position={contextMenuPosition}
          onClose={handleContextMenuClose}
          onItemClick={() => {
            handleRedirectNewTab();
          }}
        />
*/}
      </ListCustomerContext.Provider>
    </>
  )
}

export const ListCustomerContext = createContext({});
export default ListCustomer;
