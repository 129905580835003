import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { Button, Grid, IconButton, Tooltip } from '@material-ui/core';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  includes as _includes,
  isEmpty as _isEmpty,
  isUndefined as _isUndefined,
  toNumber as _toNumber
} from 'lodash';

import AppHelper from '../../helpers/AppHelper';
import { CustomerService } from '../../services';
import { useUI } from '../../app/context/ui';
import { addCustomer } from '../../app/store/mm/customerSlice';
import { updateScrollCustomer } from '../../app/store/mm/scrollCustomerSlice';
import { PreviousCustomerContext } from './EditCustomer';

const customerService = new CustomerService();

const ScrollCustomer = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const locationState = location.state || [];
  const condition = locationState?.condition || '';
  const find = (location.state?.find) ? locationState?.find : '';
  const {blockUI, snackbarUI} = useUI();
  const query = new URLSearchParams(useLocation().search);
  const link = _isUndefined(localStorage.getItem('listCustomerSelect')) ? ''
    : _isEmpty(localStorage.getItem('listCustomerSelect')) ? '' : localStorage.getItem('listCustomerSelect');
  const recordScroll = useSelector(({ mm }) => mm.scroll.values.record) ?? 0;
  const rowCount = useSelector(({ mm }) => mm.scroll.values.rowCount) ?? 0;
  const page = _toNumber(query.get('page')) || 0;
  const pageSize = _toNumber(query.get('pageSize')) || 50;
  const sortCustom = (query.get('sortCustom') !== 'undefined' ) ? (query.get('sortCustom') !== null ? query.get('sortCustom') : '-id') : '-id';
  const filterCustom = (query.get('filterCustom') !== 'undefined' ) ? query.get('filterCustom') : '';
  const activeOrderStatus = query.get('activeOrderStatus');
  const record = _toNumber(query.get('record')) || 0;
  const [rowsState, setRowsState] = useState({
    pageSize:1,
    rows: [],
    rowCount: 0,
    ...page,
    record: (record + 1) + (pageSize * page),
    sortCustom,
    filterCustom
  });

  const search = `?render=paginate&sort=${rowsState.sortCustom}&limit=${rowsState.pageSize}&page=RECORD_ID${link}${
    _includes(link, '&filter[status_orders]=0') ? '' : ``
  }`;

  const { setPrevious } = useContext(PreviousCustomerContext);

  const getRecordCustomer = useCallback(async (record = rowsState.record) => {
    try {
      blockUI.current.open(true);
      customerService.getAccessToken();
      let r1;
      let _query = new URLSearchParams(localStorage.getItem('query'));
      _query.set('limit', rowsState.pageSize);
      _query.set('page', record);
      r1 = await customerService.list(`?${_query.toString()}`);
      setRowsState({...rowsState, rowCount: r1.data.total, record, rows: r1.data.data});
      blockUI.current.open(false);
      return r1.data.data[0] ?? [];
    } catch (e) {
      blockUI.current.open(false);
      AppHelper.checkError(e, snackbarUI);
    }
  }, [blockUI, rowsState, search, snackbarUI, activeOrderStatus, condition, find]);


  const onPrevCustomer = () => {
    const record = recordScroll > 1 ? recordScroll - 1 : 1;
    reloadCustomer(record);
  }

  const onNextCustomer = () => {
    const record = rowCount > recordScroll ? recordScroll + 1 : rowCount;
    reloadCustomer(record);
  }

  const reloadCustomer = (record) => {
    getRecordCustomer(record).then(res => {
      try {
        let options = {};
        if(props.typeLog){
          options = {search: '?tap=3', state: {id: res.id, typeLog: true, condition: condition, find: find}};
        }else{
          options = {state: {id: res.id, condition: condition, find: find}};
        }
        history.push({...options});
        dispatch(addCustomer({...res}));
        setPrevious(res);
      } catch (e) {
        AppHelper.checkError(e, snackbarUI);
      }
    });
  }

  const handleUnload = useCallback(() => {
    localStorage.setItem('RECORD_ID', rowsState.record);
  }, [rowsState]);

  useEffect(() => {
    (async function init() {
      let record = localStorage.getItem('RECORD_ID') !== null ?
          _toNumber(localStorage.getItem('RECORD_ID')) :
          rowsState.record;
      await getRecordCustomer(record);
      localStorage.removeItem('RECORD_ID');
    })();
  }, []);

  useEffect(() => {
    window.removeEventListener("beforeunload", handleUnload);
    window.addEventListener("beforeunload", handleUnload);
  }, [rowsState]);

  useEffect(() => {
    dispatch(updateScrollCustomer({
      ...rowsState
    }));
  }, [rowsState]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Tooltip title="Prev">
          <IconButton aria-label="Prev" onClick={() => onPrevCustomer()}>
            <NavigateBeforeIcon/>
          </IconButton>
        </Tooltip>
        <Tooltip title="label">
          <Button size="small" variant="contained">{recordScroll ?? 1} of {rowCount}</Button>
        </Tooltip>
        <Tooltip title="Next">
          <IconButton aria-label="Next" onClick={() => onNextCustomer()}>
            <NavigateNextIcon/>
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}

export default ScrollCustomer;
